// 一些全局的config配置
const modeUrlObj = {
  // 生产环境
  'production': {
    baseURL: 'https://www.ysdar.com/api',
    authBaseURL: ''
  },
  // 开发环境
  'development': {
    //baseURL: 'http://192.168.111.202:8081/website',
    //baseURL: 'https://www.ysdar.com/api',
    //baseURL: 'http://27.17.40.186:7744/website',
    //baseURL: 'http://192.168.111.194:8081/website',
    baseURL: 'http://192.168.111.16:8081/website',  
	//baseURL: 'https://414n7140p9.picp.vip/ysd-api',
    authBaseURL: ''
  },
  // 测试环境
  'test': {
    baseURL: 'http://192.168.111.16/ysd',
    authBaseURL: ''
  }
}
export default modeUrlObj[process.env.NODE_ENV]
