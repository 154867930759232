import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        user
    },
    getters,
    state: {
        headerTab: null
    },
    mutations: {
        updateTab(state, num) {
            state.headerTab = num
        }
    }
})

export default store
