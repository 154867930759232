import Vue from 'vue'
import App from './App.vue'
import { router } from './router/index'
import store from './store/index'
// import './utils/rem'
// import './utils/flexible'
import 'lib-flexible/flexible.js'
import config from './config/index' // 路径配置
import "amfe-flexible" // 引入flexible

import MetaInfo from 'vue-meta-info'
import xss from 'xss';
Vue.prototype.xss = xss

Vue.use(MetaInfo)


let uploadUrl = config.baseURL + "/file/upload";
Vue.prototype.uploadUrl = uploadUrl
// import { } from 'mint-ui';  // 按需引入mint-ui。不需要引入样式，需要在Babel.config进行配置

// console.log(`run in ${process.env.NODE_ENV}`)
//设置为 false 以阻止 vue 在启动时生成生产提示
// router.afterEach(() => {
//   window.scrollTo(0, 0);
// })
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('custom-render-trigger'))
  }

}).$mount('#app')
