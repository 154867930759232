<template>
  <div id="app" ref="vueApp" style="display:none">
    <app-header v-if="header_show" :headTitle="headerTitle"></app-header>

    <keep-alive>
      <router-view class="middle" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <div>
      <router-view class="middle" v-if="isRouterAlive&&!$route.meta.keepAlive"></router-view>
    </div>

    <app-bottom v-if="bottom_show" :bottomTitle="bottomTitle"></app-bottom>
    <div class="toTop" v-if="toTopShow">
      <div @click="toTop">
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>
  </div>
</template>

<script>
//引用组件
import AppHeader from "./views/components/app_header.vue";
import AppBottom from "./views/components/app_bottom.vue";
import config from "./config/index"; // 路径配置
export default {
  name: "App",
  data() {
    return {
      header_show: true, //判断头部是否显示
      headerTitle: "我是头部",
      bottom_show: true, //判断尾部是否显示
      bottomTitle: "我是尾部",
      scrollTop: 0,
      toTopShow: false,
      isRouterAlive: true
    };
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  components: {
    AppHeader,
    AppBottom
  },
  watch: {
    // $route(to, from) {
    //   this.reload();
    // }
  },
  created() {
    this.isMobile();
  },
  mounted() {
    this.$refs.vueApp.style.display = "block";
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      window.addEventListener(
        "hashchange",
        () => {
          let currentPath = window.location.hash.slice(1);
          if (this.$route.path !== currentPath) {
            this.$router.push(currentPath); // 主动更改路由界面
          }
        },
        false
      );
    }

    window.addEventListener("popstate", this.popstate, false);
    document.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener("popstate", this.popstate, false);
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    scrollTo(el, from = 0, to = 0, duration = 500, endCallback) {
      if (!window.requestAnimationFrame) {
        window.requestAnimationFrame =
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.msRequestAnimationFrame ||
          function(callback) {
            return window.setTimeout(callback, 1000 / 60);
          };
      }
      const difference = Math.abs(from - to);
      const step = Math.ceil((difference / duration) * 50); // 总的长度 除以 总时间   再乘以 50  => 2000/1000 * 50 = 100 相当于一步走100px
      function scroll(start, end, step) {
        let d = 0;
        if (start == end) {
          // 如果相等了 就停止执行
          endCallback && endCallback();
          return;
        } else if (start > end) {
          // 如果开始位置大于最后位置 开始做减法
          d = start - step < end ? end : start - step;
        } else {
          d = start + step > end ? end : start + step;
        }

        if (el === window) {
          window.scrollTo(start, d); // 就从开始 start 滚动到 d 处
        } else {
          el.scrollTop = d;
        }
        window.requestAnimationFrame(() => scroll(d, end, step));
      }

      scroll(from, to, step);
    },
    toTop() {
      setTimeout(() => {
        const sTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        this.scrollTo(window, sTop, 0, 1000, () => {});
      }, 0);
    },
    handleScroll() {
      this.scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      if (this.scrollTop > 500) {
        this.toTopShow = true;
      } else {
        this.toTopShow = false;
      }
    },

    popstate() {
      setTimeout(() => {
        this.reload();
      }, 10);
    },
    // 是否显示头部
    header(bool, title) {
      console.log(bool, title);
      this.header_show = bool;
      this.headerTitle = title;
    },
    reload() {
      this.isRouterAlive = false;
      this.header_show = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
        this.header_show = true;
      });
    },
    isMobile() {
      this.flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      console.log(this.flag);
      if (this.flag === null) {
        console.log("pc端");
      } else {
        console.log("移动端");
        window.location.href = "https://www.ysdar.com/app";
      }
    }
  }
};
</script>
<style lang="scss">
@import "assets/css/reset";
$vm_w_base: 1920;
$vm_h_base: 1080;
@function vm_w($px) {
  @return ($px / 1920) * 100vw;
}

* {
  margin: 0;
  padding: 0;
}
.toTop {
  position: fixed;
  bottom: vm_w(167);
  right: vm_w(64);
  width: vm_w(48);
  height: vm_w(48);
  text-align: center;
  line-height: vm_w(48);
  background: #e6e6e6;
  border: vm_w(1) solid #b4b4b4;
  border-radius: 4px;
  cursor: pointer;
  z-index: 100;

  font-size: vm_w(18);
  color: #666666;
  & :hover {
    
  }
}

.el-select-dropdown__item.selected {
  color: $chooseColor !important;
}

button:focus,
button:active:focus,
button.active:focus,
button.focus,
button:active.focus,
button.active.focus {
  outline: none;
  border-color: transparent; // 若是本身具有 border 请删除此属性
  box-shadow: none;
}
.middle {
}
</style>
