import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('../views/index'),
            meta: {
                keepAlive: true // 需要缓存
            }
        },
        {
            path: '/search',
            name: 'search',
            component: () => import('../views/search/search'),
            meta: {
                keepAlive: false // 不需要缓存
            }
        },
        {
            path: '/aboutUs',
            name: 'aboutUs',
            component: () => import('../views/aboutUs/aboutUs'),
            meta: {
                keepAlive: false // 不需要缓存
            }
        },
        {
            path: '/Projects',
            name: 'Projects',
            component: () => import('../views/Projects/Projects'),
            meta: {
                keepAlive: false // 不需要缓存
            }
        },
        {
            path: '/CompanyNews',
            name: 'CompanyNews',
            component: () => import('../views/CompanyNews/CompanyNews'),
            meta: {
                keepAlive: false // 不需要缓存
            }
        },
        {
            path: '/TalentConcept',
            name: 'TalentConcept',
            component: () => import('../views/TalentConcept/TalentConcept'),
            meta: {
                keepAlive: false // 不需要缓存
            }
        },
      
    ]
})
