<template>
  <div class="bottom">
    <div class="logo_img">
      <img src="../../assets/img/logo@2x.png" alt />
    </div>

    <div class="title1">
      <div class="title_tit">关于我们</div>
      <div class="title_text" @click="togo(2,0)">公司简介</div>
      <div class="title_text" @click="togo(2,1)">公司历程</div>
      <div class="title_text" @click="togo(2,2)">公司架构</div>
      <div class="title_text" @click="togo(2,3)">产品线规划</div>
      <div class="title_text" @click="togo(2,4)">核心业务</div>
    </div>

    <div class="title1" style="margin-left:26%">
      <div class="title_tit">商业项目</div>
      <div class="title_text" @click="togo(3,0)">运营期项目</div>
      <div class="title_text" @click="togo(3,1)">筹备期项目</div>
    </div>
    <div class="title1" style="margin-left:33%">
      <div class="title_tit">最新资讯</div>
      <div class="title_text" @click="togo(4,0)">公司新闻</div>
      <div class="title_text" @click="togo(4,1)">行业新闻</div>
    </div>

    <div class="title1" style="margin-left:40%">
      <div class="title_tit">联系我们</div>
      <div class="title_text" @click="togo(5,0)">人才招聘</div>
      <div class="title_text" @click="togo(5,1)">联系方式</div>
      <div class="title_text" @click="togo(5,2)">意见反馈</div>
      <div class="title_text" @click="togo(5,3)">项目合作</div>
      <div class="title_text" @click="togo(5,4)">供应商报名</div>
    </div>

    <div class="phone">027-85803033</div>

    <div class="QrCode_list">
      <div class="qrcode_img">
        <img src="../../assets/img/ysdgzh.png" alt />
        <span>亿事达公众号</span>
      </div>
      <div class="qrcode_img" style="margin-left:32px">
        <img src="../../assets/img/ijj_qrcode.png" alt />
        <span>季佳好铺小程序</span>
      </div>
      <div class="qrcode_img" style="margin-left:32px">
        <img src="../../assets/img/ijj_qrcode2.jpg" alt />
        <span>i季佳小程序</span>
      </div>
    </div>

    <div class="bottom_bot">
      <div class="bottom_bot_text">
        <p>
          武汉亿事达商业运营管理有限公司·所有版权©2021
          <a
            target="_blank"
            href="https://beian.miit.gov.cn/#/Integrated/index"
          >鄂ICP备20005373号-2</a>
        </p>
        <div style="width:300px;margin:0 auto;">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010302002320"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
          >
            <img src="../../assets/img/beiAn.png" style="float:left;" />
            <p
              style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;"
            >鄂公网安备 42010302002320号</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  name: "app_bottom",
  inject: ["reload"],
  data() {
    return {
      subhead: 0
    };
  },
  // 我是子组件，接收来自父组件的信息
  props: ["bottomTitle"],
  methods: {
    togo(tab, subtab) {
      Cookies.set("headerTab", tab);
      switch (tab) {
        case 2:
          this.header_tab = tab;
          this.$router.push("/aboutUs?subtab=" + subtab);
          this.reload();
          this.toTop();
          break;
        case 3:
          this.header_tab = tab;
          this.$router.push("/Projects?subtab=" + subtab);
          this.reload();
          this.toTop();
          break;
        case 4:
          this.header_tab = tab;
          this.$router.push("/CompanyNews?subtab=" + subtab);
          this.reload();
          this.toTop();
          break;
        case 5:
          this.header_tab = tab;
          this.$router.push("/TalentConcept?subtab=" + subtab);
          this.reload();
          this.toTop();
          break;

        default:
          break;
      }
    },
    toTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
$vm_w_base: 1920;
$vm_h_base: 1080;
@function vm_w($px) {
  @return ($px / 1920) * 100vw;
}
@keyframes fade-in {
  from {
    opacity: 0;
    filter: alpha(opacity = 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 0);
  }
  to {
    opacity: 1;
    filter: alpha(opacity = 100);
    filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 100);
  }
}
.bottom {
  width: 100%;
  box-sizing: border-box;
  height: vm_w(459);
  background-color: #f1f1f1;
  position: relative;
  animation: fade-in 0.6s;
  -webkit-animation: fade-in 0.6s;
  -moz-animation: fade-in 0.6s;
  -ms-animation: fade-in 0.6s;
  -o-animation: fade-in 0.6s;
}
.logo_img {
  width: vm_w(328);
  display: inline-block;
  margin-left: vm_w(364);
  margin-top: vm_w(64);
  position: absolute;
}
.logo_img img {
  width: 100%;
  align-items: center;
}
.bottom_bot {
  position: absolute;
  bottom: 0px;
  height: vm_w(86);
  width: 100%;
  border-top: solid 1px #aaaaaa;
  text-align: center;
  display: table;
}
.bottom_bot_text {
  margin: 0 auto;
  width: vm_w(356);
  font-size: vm_w(14);
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: center;
  color: #666666;
  line-height: vm_w(24);
  display: table-cell;
  vertical-align: middle;

  & a:hover {
    color: #124db3;
  }
}

.title1 {
  margin-left: vm_w(364);
  margin-top: vm_w(142);
  font-size: vm_w(14);
  font-family: Source Han Sans CN, Source Han Sans CN-Normal;
  color: #5d5d5d;
  position: absolute;
}
.title_tit {
  font-size: vm_w(18);
  text-decoration: underline;
  font-weight: 500;
  color: #333333;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  padding-bottom: vm_w(5);
}
.title_text {
  margin-top: vm_w(10);
  cursor: pointer;
}
.title_text:hover {
  color: $chooseColor;
}
.phone {
  font-size: vm_w(24);
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  color: #333333;
  position: absolute;
  margin-left: vm_w(1388);
  margin-top: vm_w(83);
}
.QrCode_list {
  position: absolute;
  margin-top: vm_w(142);
  margin-left: vm_w(1060);
}

.qrcode_img {
  display: inline-block;
  text-align: center;
  width: vm_w(144);
  font-size: vm_w(14);
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #333333;
}
.qrcode_img img {
  width: 100%;
  vertical-align: top;
}
.qrcode_img span {
  line-height: vm_w(30);
}
</style>